<template>
  <div class="full">
    <div class="order_nav d_f ali_c">
      <img src="./img/order_back.png" alt="" class="cur_p" @click="$router.go(-1)">
      <span>{{$route.query.name}}</span>
    </div>
    <div class="ff_content">
      <div class="card one">
        <div class="head">
          <span>{{info.name+'-'+info.roomTypeName}} <span> | </span></span>
          <span v-if="info.roomType.window>0">有窗 <span> | </span></span>
          <span v-if="info.roomType">{{info.roomType.capacity}}人入住 <span> | </span></span>
          <span v-if="info.breakfast>0">含早餐 <span v-if="false"> | </span></span>
        </div>
        <div class="date d_f ali_c">
          <img src="./img/ff_date.png" alt="">
          <div>
            <span>{{$formatDate(new Date(dateArr[0]),"MM/dd")}}</span><span> - </span><span>{{$formatDate(new Date(dateArr[1]),"MM/dd")}}</span>
          </div>
          <span>{{'住'+$getDaysBetween(dateArr[0],dateArr[1])+'晚'}}</span>
        </div>
        <div v-if="$route.query.refundNotice">{{$route.query.refundNotice}}</div>
      </div>
      <div class="card two">
        <div class="title">入住信息</div>
        <div>
          <div class="bb_out d_f ali_c" :class="!showRoomNum ? 'bb' : ''">
            <div class="left d_f ali_c">房间数</div>
            <div class="center black flex_1"><span>{{acRoomNum+1}}间</span><span>（每间最多住{{info.roomType.capacity}}人）</span></div>
            <img class="room_num cur_p" :class="showRoomNum ? 'rotateNum':''" src="./img/ff_room_num.png" alt="" @click="showRoomNum = !showRoomNum">
          </div>
        </div>
        <div v-if="showRoomNum" class="room_num_out">
          <div class="item" @click="acRoomNum = index" :class="index == acRoomNum ? 'ac' : ''" v-for="(item,index) in info.minStock" :key="index">{{index+1}}</div>
        </div>
        <!--房客姓名-->
        <div>
          <div class="bb_out d_f ali_c bb" v-for="(item,index) in subInfo.reqOrderDetails" :key="index">
            <div class="left d_f ali_c">
              <span>房客姓名</span>
              <div>{{index+1}}</div>
            </div>
            <div class="center black flex_1 d_f ali_c p_r">
              <input class="flex_1" type="text" placeholder="请填写入住人姓名" v-model="item.visitorName">
              <van-icon class="fs_18 p_a close_icon" name="close" v-if="item.visitorName" @click="item.visitorName = ''" color="#929191"/>
            </div>
          </div>
        </div>
        <!--手机号-->
        <div>
          <div class="bb_out d_f ali_c bb">
            <div class="left d_f ali_c">手机号</div>
            <div class="center black flex_1 d_f ali_c p_r">
              <input class="flex_1" type="text" placeholder="用于接收酒店下发短信" v-model="subInfo.telephone">
              <van-icon class="fs_18 p_a close_icon" name="close" v-if="subInfo.telephone" @click="subInfo.telephone = ''" color="#929191"/>
            </div>
            <!--唐经理要求先不做-->
            <img class="ff_phone cur_p" src="./img/ff_phone.png" alt="" @click="getPhoneNum" v-if="false">
          </div>
        </div>
        <!--预计到店-->
        <div>
          <div class="bb_out d_f ali_c" @click="showTime = true">
            <div class="left d_f ali_c">预计到店</div>
            <div class="center black flex_1"><span>{{timesArr[acTime].name}}</span><span>（房间将整晚保留）</span></div>
            <img class="time_right cur_p" src="./img/ff_time_right.png" alt="">
          </div>
        </div>
      </div>
      <!-- <div class="integral"> -->
      <div class="integral" v-show="pointUseSwitch && canUseIntegral && integral > 0">
        <div class="title d_f ali_b j_s">
          <span>积分优惠</span>
          <span class="jf_link">《<a @click="showJfPop = true">积分规则</a>》</span>
        </div>
        <div>
          <div class="sub">
            <span>积分抵扣</span>
            <span>本单可使用 {{showTotalIntegral}} 积分 抵扣 {{showTotalIntegralMoney / 100}} 元</span>
            <div class="check" :class="useIntegral?'checked':''" @click="integralCheck()"></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="title">开具发票</div>
        <div class="tip_out t_a">
          请离店后，联系酒店前台开具发票(仅支持普票)
        </div>

      </div>
      <timeTip></timeTip>
    </div>
    <div class="p_f go_pay d_f ali_c">
      <span class="price">￥{{(totalPrice - totalIntegralMoney / 100).toFixed(2)}}</span>
      <div class="d_f ali_c flex_1 cur_p">
        <div class="view" v-if="!showPDPop" @click="showPDPop = true">
          <span>价格明细</span>
          <img src="./img/ff_price_top.png" alt="">
        </div>
        <div class="view" v-else @click="showPDPop = false">
          <span>收起</span>
          <img src="./img/ff_price_down.png" alt="">
        </div>
        <div class="integral" v-show="useIntegral">
          <li>积分抵扣</li>
          <p>-￥{{totalIntegralMoney / 100}}</p>
        </div>
      </div>
      <div class="btn t_a cur_p" @click="goPay">去支付</div>
    </div>

    <!--价格明细弹窗-->
    <div class="pd_out p_f" v-if="showPDPop">
      <div class="pd p_a">
        <div class="head d_f ali_c">
          <img class="cur_p" src="./img/ff_pd_close.png" alt="" @click="showPDPop = false">
          <span class="flex_1 t_a">付款明细</span>
        </div>
        <div class="item d_f ali_c">
          <span>{{stayDate + ' ×'+ priceArr.length +' 晚'}}</span>
          <span class="t_r flex_1">{{' × '+(acRoomNum+1)+'间'}}</span>
        </div>
        <div class="detaillist">
          <div class="item" v-for="(item, index) in subInfo.reqOrderDetails" :key="index">
            <span>{{item.visitorName}}</span>
            <span>{{roomName}}</span>
            <span><div class="jfdk" v-show="useIntegral">积分抵扣 减{{item.integralMoney/100}}</div>￥{{(item.money - item.integralMoney)/100}}</span>
          </div>
        </div>
        <div class="total d_f ali_c">
          <span>总计</span>
          <span class="t_r flex_1"><div class="jfdk" v-show="useIntegral">积分抵扣 减{{totalIntegralMoney/100}}</div>￥{{totalPrice - totalIntegralMoney / 100}}</span>
        </div>
      </div>
    </div>

    <!--预计到店弹窗-->
    <div class="pd_out tp_out p_f" v-if="showTime">
      <div class="tp p_a">
        <div class="head d_f ali_c">
          <span class="flex_1">预计到店</span>
          <img class="cur_p" src="./img/ff_pd_close.png" alt="" @click="showTime = false">
        </div>
        <div class="tip">房间整晚保留，14:00前到店可能需要等待。</div>
        <div class="clear">
          <div class="items" v-for="(item,index) in timesArr" :class="acTime === index ? 'ac' : ''" @click="acTime = index">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!--积分规则弹窗-->
    <jfRules v-if="showJfPop" @close="showJfPop = false"></jfRules>
  </div>
</template>

<script>
  import timeTip from "./component/time_tip";
  import apis from './utils/apis'
  import { Icon, Toast} from 'vant'
  import jfRules from "../home/order/jfRules";
  export default {
    name: "fillInformation",
    components:{
      timeTip,
      jfRules,
      'van-icon': Icon,
    },
    watch:{
      'acRoomNum'(n,o){
        if((n+1)<this.subInfo.reqOrderDetails.length){
          this.subInfo.reqOrderDetails.length = n+1
        }
        if((n+1)>this.subInfo.reqOrderDetails.length){
          let addNum = n+1-this.subInfo.reqOrderDetails.length;
          for(let i = 0;i<addNum;i++){
            this.subInfo.reqOrderDetails.push({
              "certificateNumber": "",
              "certificateType": "0",
              "commodityId": JSON.parse(sessionStorage.getItem('roomInfo')).roomId,
              "visitorName": "",
              'money': 0,
              'discountPoints': 0,
              'integralMoney': 0
            })
          }
        }
        this.subInfo.reqOrderDetails = JSON.parse(JSON.stringify(this.subInfo.reqOrderDetails));
        this.getPriceTotal(n+1)
      },
      subInfo: { // 深度监听
        handler(val, oldVal){
          this.personIntegral()
        },
        deep:true
      }
    },
    data() {
      return {
        apis:{
          queryPoint:'/pointCenter/queryPoint', // 查询可用积分
          getSysPointConfig: 'point/getSysPointConfig' // 查询积分比例
        },
        showJfPop:false,
        showRoomNum:false,
        showTime:false,
        showPDPop:false,
        acTime:0,
        timesArr:[{}],
        info:JSON.parse(sessionStorage.getItem('roomInfo')),
        dateArr:sessionStorage.getItem('dateRage') ? JSON.parse(sessionStorage.getItem('dateRage')) : [new Date().getTime(),Number(new Date().getTime()+(24*3600*1000))],//选中的日期区间
        acRoomNum:0,
        subInfo:{
          "date": "",
          "bookLeaveDate": "",
          "linkmanName": '',
          "orderType": 3,
          "remark": "",
          "arriveTime":"",
          "totalStayNum":'',
          "reqOrderDetails": [
            {
              "certificateNumber": "",
              "certificateType": "0",
              "commodityId": JSON.parse(sessionStorage.getItem('roomInfo')).roomId,
              "visitorName": "",
              'money': 0,
              'discountPoints': 0,
              'integralMoney': 0
            }
          ],
          "telephone": "",
          'totalDiscountPoints': 0,
          'source': 3,
          "token": this.$global.token
        },
        priceArr: [],
        roomName: this.$route.query.r, // 房间名称
        totalPrice: 0,
        pointUseSwitch: JSON.parse(this.$route.query.i), // 能否使用积分 商品
        canUseIntegral: false, // 能否使用积分 总开关
        integral: 0, // 可使用积分
        integralValue: JSON.parse(this.$route.query.u), // 单个商品可使用积分
        useIntegral: false, // 是否使用积分
        integralExchangeMoneyScale: 1, //积分比列
        totalIntegral: 0, // 总共能兑换的积分
        totalIntegralMoney: 0, // 积分兑换金额
        showTotalIntegral: 0, // 提示 本次使用可使用积分
        showTotalIntegralMoney: 0, // 提示 本次积分抵扣金额
        stayDate: '' // 入驻时间
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      this.enumsList('arrival_time',this.delTime);
      this.getPrice();
      this.getSysPointConfig() // 查询积分比例
      this.queryPoint()  // 查询可用积分
    },
    activated(){
      this.getSysPointConfig()
      this.queryPoint()
    },
    methods: {
      // 查询积分比列
      getSysPointConfig(){
        let me = this;
        me.$post({
          url:me.apis.getSysPointConfig,
          params: {}
        }).then(res => {
          me.integralExchangeMoneyScale = res.data.integralExchangeMoneyScale
          if(res.data.isEnableIntegralUse == 1){
            me.canUseIntegral = true
          }
          this.personIntegral()
        });
      },
      // 查询可用积分
      queryPoint() {
        let me = this;
        me.$post({
          url:me.apis.queryPoint,
          params: {
            token: me.$global.token
          }
        }).then(res => {
          if(res.data.data && res.data.data.currentIntegral > 0){
            me.integral = res.data.data.currentIntegral
            this.personIntegral()
          }
        })
      },
      // 计算每一个人使用的积分
      personIntegral() {
        // 查看单个用户适用积分
        if(this.useIntegral){
          let integral = this.integral;
          this.totalIntegral = 0
          this.totalIntegralMoney = 0
          for(let x = 0; x < this.subInfo.reqOrderDetails.length; x++){
            let integralValue = 0, integralMoney = 0
            for(let y = 0; y < this.priceArr.length; y++){
              let price = this.priceArr[y].sellPrice;
              if (integral > this.integralValue) { // 剩余可用积分大于单个使用积分时
                integralValue += this.integralValue
                if(this.integralValue * this.integralExchangeMoneyScale > price){ // 当积分兑换金额大于单价时
                  integralMoney += price
                }else{
                  integralMoney += this.integralValue * this.integralExchangeMoneyScale
                }
                integral -= this.integralValue
              }else{ // 剩余可用积分，小于单个使用积分
                if(integral > 0){
                  integralValue += integral
                  if(integral * this.integralExchangeMoneyScale > price){ // 当积分兑换金额大于单价时
                    integralMoney += price
                  }else{
                    integralMoney += integral * this.integralExchangeMoneyScale
                  }
                  integral = 0
                }
              }
            }
            this.subInfo.reqOrderDetails[x].discountPoints = integralValue
            this.subInfo.reqOrderDetails[x].integralMoney = integralMoney
            this.totalIntegral += integralValue
            this.totalIntegralMoney += integralMoney
          }
        }else{
          for(let i = 0; i < this.subInfo.reqOrderDetails.length; i++){
            this.subInfo.reqOrderDetails[i].discountPoints = 0
            this.subInfo.reqOrderDetails[i].integralMoney = 0
          }
          this.totalIntegral = 0
          this.totalIntegralMoney = 0
        }
        // 计算每一个人的总金额 以及 使用积分兑换金额提示
        let integral = this.integral, showTotalIntegral = 0, showTotalIntegralMoney = 0; // 剩余总积分 总共使用积分     积分抵扣总金额
        for(let x = 0; x < this.subInfo.reqOrderDetails.length; x++){
          let totalMoney = 0
          for(let y = 0; y < this.priceArr.length; y++){
            let price = this.priceArr[y].sellPrice;
            if (integral > this.integralValue) { // 剩余可用积分大于单个使用积分时
              showTotalIntegral += this.integralValue
              if(this.integralValue * this.integralExchangeMoneyScale > price){ // 当积分兑换金额大于单价时
                showTotalIntegralMoney += price
              }else{
                showTotalIntegralMoney += this.integralValue * this.integralExchangeMoneyScale
              }
              integral -= this.integralValue
            }else{ // 剩余可用积分，小于单个使用积分
              if(integral > 0){
                showTotalIntegral += integral
                if(integral * this.integralExchangeMoneyScale > price){ // 当积分兑换金额大于单价时
                  showTotalIntegralMoney += price
                }else{
                  showTotalIntegralMoney += integral * this.integralExchangeMoneyScale
                }
                integral = 0
              }
            }
            totalMoney += price
          }
          this.subInfo.reqOrderDetails[x].money = totalMoney
        }
        this.showTotalIntegral =  showTotalIntegral
        this.showTotalIntegralMoney =  showTotalIntegralMoney
      },
      // 是否使用积分选择
      integralCheck() {
        this.useIntegral = !this.useIntegral
        this.personIntegral()
      },
      getPriceTotal(n){
        let num = 0;
        this.priceArr.map(item => {
          let price = item.sellPrice ? item.sellPrice/100 : 0;
          num += price*n
        });
        this.totalPrice = num;
      },
      //根据房间id和入住日期获取价格日历
      getPrice(){
        let _t = this;
        let endTime = isNaN(new Date(this.dateArr[1])) ? this.dateArr[1] - 24*3600*1000 : new Date(this.dateArr[1]).getTime()-24*3600*1000 ;
        _t.$get({
          url:apis.queryPriCalendarByRoomIdAndDate,
          params: {
            roomId:_t.info.roomId,
            startTime:_t.$formatDate(new Date(_t.dateArr[0]),'yyyy-MM-dd'),
            endTime:_t.$formatDate(new Date(endTime),'yyyy-MM-dd'),
          }
        }).then(res => {
          _t.priceArr = res;
          _t.getPriceTotal(1)
          let startDate = '', endDate = '';
          for(let i = 0; i < res.length; i++){
            if(i == 0){
              startDate = parseInt(res[i].stockDate.substr(5, 2)) + '月' + parseInt(res[i].stockDate.substr(8, 2)) + '日'
            }
            if(i == res.length - 1){
              endDate = parseInt(res[i].stockDate.substr(5, 2)) + '月' + parseInt(res[i].stockDate.substr(8, 2)) + '日'
            }
          }
          if(res.length == 1){
            _t.stayDate = startDate
          }else{
            _t.stayDate = startDate + '-' + endDate
          }
        }).catch(err => {
          Toast(err.message);
        });
      },
      //去支付
      goPay(){
        let _t = this;
        let pass = true,roomIndex = 0;
        _t.subInfo.reqOrderDetails.map((item,index) => {
          if(!item.visitorName) {
            pass = false;
            roomIndex = index+1
          }
        });
        if(!pass) return Toast('请输入房客姓名');
        let regPhone = /^1[3456789]\d{9}$/;//电话正则
        if(!regPhone.test(_t.subInfo.telephone)) return Toast('电话输入有误');
        _t.subInfo.date = _t.$formatDate(new Date(_t.dateArr[0]),'yyyy-MM-dd');
        _t.subInfo.bookLeaveDate = _t.$formatDate(new Date(_t.dateArr[1]),'yyyy-MM-dd');
        _t.subInfo.linkmanName = _t.subInfo.reqOrderDetails[0].visitorName;
        _t.subInfo.arriveTime = _t.timesArr[_t.acTime].value;
        _t.subInfo.totalStayNum = _t.$getDaysBetween(_t.dateArr[0],_t.dateArr[1]);
        _t.subInfo.totalDiscountPoints = _t.totalIntegral
        let price = _t.totalPrice - (_t.totalIntegralMoney / 100)
        _t.$post({
          url:apis.createOrder,
          params: _t.subInfo
        }).then(res => {
          _t.$router.push({
            path:'/hotelPay',
            query:{
              hotelOrderNum:res.data,
              price: price
            }
          })
        }).catch(err => {
          Toast(err.message);
        });
      },
      //到店时间枚举
      delTime(info){
        this.timesArr = info;
      },
      enumsList(key,callback){
        this.$get({
          url: apis.enumsList,
          params: {
            key:key
          }
        }).then(rsp => {
          callback(rsp)
        });
      },
      //获取通讯录手机号
      getPhoneNum(){

      },
    }
  }
</script>

<style scoped lang="scss">
  .pd_out{
    .tp{
      animation: goToTop .5s;
      .items{
        float: left;
        height:35px;
        background:rgba(233,233,233,1);
        border-radius:3px;
        line-height: 35px;
        text-align: center;
        font-size:14px;
        font-weight:500;
        color:rgba(62,62,62,1);
        width: calc((100% - 42px) / 3);
        margin: 0 21px 18px 0;
        &.ac{
          color: white;
          background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
        }
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      .tip{
        font-size:12px;
        font-weight:500;
        color:rgba(142,142,142,1);
        margin-bottom: 25px;
      }
      .head{
        font-size:17px;
        font-weight:bold;
        color:rgba(62,62,62,1);
        margin-bottom: 12px;
        img{
          width: 14px;
        }
      }
      width: 100%;
      box-sizing: border-box;
      bottom: 0;
      background:rgba(255,255,255,1);
      border-radius:3px 3px 0px 0px;
      padding: 21px;
    }
    &.tp_out{
      z-index: 5;
    }
    .pd{
      animation: goToTop49 .5s;
      .total{
        margin: 5px 0 19px 0;
        .jfdk{
          display: inline-block;
          border: 1px solid #E93411;
          color: #E93411;
          border-radius: 15px;
          padding: 2px 5px;
          font-size: 12px;
          vertical-align: text-top;
          margin-right: 10px;
        }
      }
      .item{
        margin-bottom: 19px;
        font-size:13px;
      }
      .head{
        img{
          width: 14px;
        }
        padding-right: 14px;
        margin-bottom: 28px;
      }
      font-size:17px;
      font-weight:500;
      color:rgba(0,0,0,1);
      width: 100%;
      box-sizing: border-box;
      padding: 15px 20px;
      background:rgba(255,255,255,1);
      border-radius:7px 7px 0px 0px;
      bottom: 49px;
      border-bottom: 1px solid #d0d0d0;
    }
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.51);
  }
  .go_pay{
    .btn{
      height:38px;
      background:rgba(245,111,85,1);
      border-radius:5px;
      line-height: 38px;
      font-size:15px;
      font-weight:500;
      width: 106px;
      color:rgba(255,255,255,1);
    }
    .flex_1{
      font-size:13px;
      font-weight:500;
      color: #20c177;
      line-height:17px;
      margin-left: 11px;
      justify-content: space-between;
      img{
        margin-left: 5px;
        width: 9px;
      }
    }
    .price{
      font-size:17px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .integral{
      margin-right: 10px;
      text-align: right;
      color: #333;
      li{
        font-size: 12px;
        list-style: none;
      }
      p{
        font-size: 12px;
        color: #FF3E6E;
      }
    }
    z-index: 2;
    padding: 0 21px;
    box-sizing: border-box;
    height:49px;
    background:rgba(255,255,255,1);
    box-shadow:0px 1px 3px 0px rgba(10,5,1,0.2);
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .bottom_tip{
    padding: 0!important;
  }
  .card{
    .tip_out{
      height:49px;
      background:rgba(255,255,255,1);
      border:1px dotted rgba(208,208,208,1);
      border-radius:3px;
      line-height: 49px;
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .title{
      font-size:17px;
      font-weight:bold;
      margin-bottom: 15px;
    }
    &.two{
      .title{
        margin-bottom: 0;
        padding-top: 15px;
      }
      .room_num_out{
        .item{
          font-size:13px;
          font-weight:500;
          color:rgba(62,62,62,1);
          text-align: center;
          height:21px;
          background:rgba(255,255,255,1);
          border-radius:3px;
          line-height: 21px;
          margin-bottom: 9px;
          margin-right: 7px;
          float: left;
          width: calc((100% - 36px) / 5);
          &.ac{
            background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
            color: white;
          }
          &:nth-child(5n){
            margin-right: 0;
          }
        }
        background-color: #F0F0F0;
        padding-top: 9px;
        &::after{
          content: ' ';
          display: block;
          clear: both;
        }
      }
      .bb_out{
        .close_icon{
          right: 0;
          top: 0;
        }
        padding: 14px 0;
        font-size:13px;
        .center{
          font-weight:bold;
          color:rgba(62,62,62,1);
          input{
            font-size:13px;
            color:rgba(62,62,62,1);
            outline: none;
            border: none;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #AEAEAE;
          }
          input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #AEAEAE;
          }
          input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #AEAEAE;
          }
          input:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #AEAEAE;
          }
        }
        .left{
          div{
            width: 13px;
            height: 13px;
            text-align: center;
            line-height: 13px;
            border: 1px solid #8E8E8E;
            font-size: 10px;
            color: #8E8E8E;
            margin-left: 6px;
            border-radius: 50%;
            position: relative;
            top: 1px;
            box-sizing: border-box;
          }
          font-weight:500;
          color:rgba(142,142,142,1);
          width: 85px;
        }
        .ff_phone{
          width: 15px;
        }
        .room_num{
          width: 13px;
          &.rotateNum{
            transform: rotate(180deg);
          }
        }
        .time_right{
          width: 6px;
        }
        &.bb{
          border-bottom: 1px solid #C0C0C0;
        }
      }
      >div{
        padding: 0 21px;
      }
      padding: 0;
    }
    .date{
      >div{
        margin-right: 14px;
      }
      img{
        width: 15px;
        margin-right: 6px;
      }
      margin-bottom: 16px;
    }
    .head{
      margin-bottom: 30px;
    }
    &.one{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);

    }
    margin-bottom: 12px;
    border-radius:3px;
    background-color: white;
    padding: 13px 21px;
  }
  .ff_content{
    height: calc(100% - 49px);
    overflow: auto;
    padding: 17px 21px 24px 21px;
    box-sizing: border-box;
  }
  .full{
    box-sizing: border-box;
    background-color: #F0F0F0;
    padding-bottom: 49px;
    .ff_content{
      .integral{
        background: #fff;
        border-radius:3px;
        margin-bottom: 12px;
        padding: 13px 21px;
        box-sizing: border-box;
        .jf_link{
          font-size: 12px;
          color: #b5b5b5;
          a{
            color: #b5b5b5;
            text-decoration: underline;
          }
        }
        .sub{
          font-size:12px;
          font-weight:400;
          color:rgba(20,18,18,1);
          display: flex;
          justify-content: space-between;
          flex-wrap:wrap;
        }
        .title{
          font-size:18px;
          font-weight:bold;
          color:rgba(20,18,18,1);
          margin-bottom: 13px;
        }
        .check{
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          border: 1px solid #c0c0c0;
          border-radius: 50%;
          cursor: pointer;
        }
        .checked{
          border: none;
          background: url(../../assets/img/icon-useintegral.png) no-repeat center center/auto 100%;
        }
      }
    }
    .detaillist{
      max-height: 300px;
      overflow-y: auto;
      background: #f5f5f5;
      margin-top: -10px;
      box-sizing: border-box;
      padding: 10px;
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding: 5px 0;
        span:first-of-type{
          width: 60px;
        }
        .jfdk{
          display: inline-block;
          border: 1px solid #E93411;
          color: #E93411;
          border-radius: 15px;
          padding: 2px 5px;
          font-size: 10px;
          vertical-align: text-bottom;
          margin-right: 10px;
        }
      }
    }
  }
</style>
